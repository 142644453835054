.recharts-surface {
    width: 100%;
    height: 100%;
}

.recharts-layer, .recharts-line {
    width: 100%;
    height: 100%;
}

.recharts-layer, .recharts-line-dots {
    max-height: 100%;
    max-width: 100%;
}